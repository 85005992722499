<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-row justify="center">
        <v-dialog v-model="loading" max-width="290">
          <v-card>
            <v-card-text class="pa-5 headline text-center">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mr-3"
              ></v-progress-circular>
              処理中
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-card
      :loading="loading"
      :disabled="loading"
      ref="form"
      elevation="2"
      class="pa-5 mt-5"
    >
      <v-card-title>
        施工事例の編集
      </v-card-title>

      <div v-if="post">
        <v-card-text>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                ref="title"
                v-model="post.title"
                :rules="rules.title"
                label="タイトル"
                required
              >
                <div
                  v-if="post.title"
                  slot="append-outer"
                  class="text-no-wrap"
                  v-text="
                    '残り ' + (titleMaxWord - post.title.length) + ' 文字'
                  "
                ></div
              ></v-text-field>
            </v-col>
            <v-col col="12" md="4">
              <v-dialog
                ref="post_date_dialog"
                v-model="post_date_model"
                :return-value.sync="post.post_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" text class="mt-2">
                    投稿日:
                    {{ post.post_date ? post.post_date : currentDate() }}
                    <v-icon size="16" right>$edit</v-icon>
                  </v-btn>
                  <v-text-field
                    dense
                    hidden
                    class="no-border"
                    v-model="post.post_date"
                    ref="post_date"
                    :error-messages="errorMessages"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker v-model="post.post_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="post_date_model = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.post_date_dialog.save(post.post_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="7">
              <v-file-input
                ref="post_image"
                v-model="post_image"
                :rules="rules.image"
                label="メイン画像"
                show-size
                counter
                prepend-icon="mdi-camera"
                @change="selectedFile"
                accept="image/jpg,image/png"
              >
              </v-file-input>
            </v-col>
            <v-col cols="12" md="1">
              <v-img
                :src="post.featured_image"
                max-height="50"
                max-width="50"
              ></v-img>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                ref="image_alt"
                v-model="post.image_alt"
                :rules="rules.image_alt"
                label="Altテキスト"
                maxLength="28"
                counter
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                ref="area"
                v-model="post.area"
                label="対応地域"
                maxLength="191"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-textarea
            ref="description"
            v-model="post.description"
            :rules="rules.description"
            rows="1"
            auto-grow
            label="説明"
            id="description"
            :value="post.description"
          >
            <div
              v-if="post.description"
              slot="append-outer"
              class="text-no-wrap"
              v-text="
                '残り ' +
                  (descriptionMaxWord - post.description.length) +
                  ' 文字'
              "
            ></div
          ></v-textarea>

          <div
            class="v-label my-2"
            :class="
              formHasErrors && (post.content == null || post.content == '')
                ? 'red--text'
                : ''
            "
          >
            内容
          </div>
          <vue-editor
            ref="content"
            v-model="post.content"
            :rules="rules.content"
            id="content"
            :editorToolbar="customToolbar"
            :customModules="customModulesForEditor"
            :editorOptions="editorSettings"
            :v-bind="post.content"
            :class="
              formHasErrors && (post.content == null || post.content == '')
                ? 'mb-0 red-text'
                : 'mb-4'
            "
          ></vue-editor>
          <div
            class="mt-2 mb-3 d-block"
            :class="
              formHasErrors && (post.content == null || post.content == '')
                ? 'red--text'
                : ''
            "
            v-if="formHasErrors && (post.content == null || post.content == '')"
          >
            内容が必要です。
          </div>

          <v-autocomplete
            v-model="post.category"
            :items="post_categories"
            item-text="title"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="カテゴリーを選択してください。"
            dense
            chips
            deletable-chips
            small-chips
            solo
          ></v-autocomplete>

          <v-autocomplete
            v-model="post.tag"
            :items="post_tags"
            item-text="title"
            item-value="id"
            :menu-props="{ maxHeight: '400' }"
            label="タグを選択してください。"
            multiple
            dense
            chips
            deletable-chips
            small-chips
            solo
          ></v-autocomplete>

          <v-switch
            ref="status"
            v-model="post.status"
            label="ステータス"
            :value="post.status"
            @click="toggle"
          ></v-switch>
        </v-card-text>

        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn text :to="{ name: 'Post' }">
            キャンセル
          </v-btn>
          <v-btn text @click="savePostToDraft">
            下書きとして保存
          </v-btn>
          <v-btn text @click="savePostAndPreview">
            プレビュー
          </v-btn>
          <v-btn color="error" text @click="deleteItem">
            施工事例を削除する
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="submit"
            :loading="loading"
            :disabled="loading"
          >
            保存する
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'
import { VueEditor } from 'vue2-editor'
import moment from 'moment'

import Quill from 'quill'
import ImageResize from 'quill-image-resize-module'
Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    VueEditor
  },
  data() {
    return {
      errorMessages: '',
      post_date_model: false,
      post_date: null,
      customToolbar: [
        [{ color: [] }, { size: [] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'code-block'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ]
      ],
      customModulesForEditor: [{ alias: 'imageResize', module: ImageResize }],
      editorSettings: {
        modules: {
          imageResize: {}
        }
      },
      breadcrumbs: [
        {
          text: '施工事例管理',
          disabled: false,
          to: { name: 'Post' },
          exact: true
        },
        {
          text: '施工事例の編集',
          disabled: false,
          to: { name: 'EditPost' },
          exact: true
        }
      ],
      formHasErrors: false,
      post: null,
      post_status: true,
      post_categories: [],
      post_tags: [],
      post_image: null,
      titleMaxWord: 28,
      descriptionMaxWord: 80,
      isPreview: false,
      rules: {
        title: [
          v => !!v || 'タイトルが必要です。',
          v =>
            (v && v.length <= this.titleMaxWord) ||
            `タイトルは${this.titleMaxWord}文字未満である必要があります`
        ],
        description: [
          v => !!v || 'Description is required',
          v =>
            (v && v.length <= this.descriptionMaxWord) ||
            `説明は${this.descriptionMaxWord}文字未満である必要があります`
        ],
        content: [v => !!v || '必要なコンテンツ'],
        image: [],
        image_alt: [v => !!v || 'Altフィールドは必須です'],
        post_date: [v => !!v || 'Post date が必要です。']
      },
      loading: false
    }
  },
  beforeMount() {
    this.getCategories()
    this.getTags()
  },
  methods: {
    currentDate() {
      const current = new Date()
      let year = current.getFullYear()
      let month = (current.getMonth() + 1).toString().padStart(2, '0')
      let day = current
        .getDate()
        .toString()
        .padStart(2, '0')
      const date = `${year}-${month}-${day}`
      return date
    },
    toggle(e) {
      if (this.post.status) {
        this.post_status = 'ACTIVE'
      } else {
        this.post_status = 'INACTIVE'
      }
    },
    selectedFile(e) {
      const imageField = e
      if (imageField && imageField.name !== '') {
        let imageSize = imageField.size / 1000
        // Check Image size less than 5MB
        if (imageSize > 5000) {
          this.rules.image.push('ファイルサイズは5MBまでです。')
        } else {
          this.rules.image = []
        }

        if (
          imageField.type != 'image/jpeg' &&
          imageField.type != 'image/jpg' &&
          imageField.type != 'image/png'
        ) {
          this.rules.image.push(
            '実績投稿で使用できる画像ファイル形式はjpgとpngです'
          )
        }

        const reader = new FileReader()
        reader.readAsDataURL(imageField)
        reader.onload = evt => {
          let img = new Image()
          img.onload = () => {
            if (img.width < '350') {
              this.rules.image = [
                '画像の横幅は350ピクセル以上のファイルをアップロードして下さい。- ※ 推奨の横幅は800ピクセル以上'
              ]
            }
          }
          img.src = evt.target.result
        }
      } else {
        this.rules.image.push('フィーチャー画像が必要')
      }
    },
    savePostToDraft() {
      this.post_status = 'DRAFT'
      this.submit()
    },
    savePostAndPreview() {
      this.post_status = false
      this.isPreview = true
      this.submit()
    },
    getCategories() {
      api
        .get('/categories')
        .then(result => {
          let resultArray = []
          result.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({ id: item.id, title: item.name })
            }
          })
          this.post_categories = resultArray
        })
        .catch(err => {
          //
        })
    },
    getTags() {
      api
        .get('/tags')
        .then(result => {
          let resultArray = []
          result.data.data.map(function(item, key) {
            if (item.name != '') {
              resultArray.push({ id: item.id, title: item.name })
            }
          })
          this.post_tags = resultArray
        })
        .catch(err => {
          //
        })
    },
    submit() {
      this.formHasErrors = false
      Object.keys(this.form).forEach(f => {
        if (
          f != 'area' &&
          f != 'content' &&
          f != 'status' &&
          f != 'tag' &&
          f != 'category'
        ) {
          if (!this.form[f]) this.formHasErrors = true
          this.$refs[f].validate(true)
        }
      })
      if (!this.formHasErrors) {
        this.updatePost()
      }
    },
    getPost(slug) {
      this.loading = true
      api
        .get('/posts/' + slug)
        .then(response => {
          this.loading = false
          this.post = response.data.data
          this.post_status = this.post.status
          this.post_date = moment(this.post.post_date).format('YYYY-MM-D')
          this.post.status = this.post.status == 'ACTIVE' ? true : false
          this.post.category = this.post.categories[0]
            ? this.post.categories[0].id
            : 0
          this.post.tag = this.post.tags.map(n => parseInt(n.id))
        })
        .catch(e => {
          //
        })
    },
    updatePost() {
      this.loading = true
      api
        .post('/posts/update/' + this.post.id, this.formData)
        .then(response => {
          this.loading = false
          if (this.isPreview) {
            this.isPreview = false
            return this.$router.push({
              name: 'PreviewPost',
              params: { id: response.data.data.id }
            })
          }
          let payload = {
            status: response.data.status,
            message: response.data.message
          }
          this.$store.commit('setAlert', payload)
          this.$router.push({ name: 'Post' })
        })
        .catch(e => {
          //
        })
    },
    deleteItem() {
      this.$swal
        .fire({
          title: 'この操作を実行してもよろしいですか？',
          text: '実行すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除する',
          cancelButtonText: 'キャンセル'
        })
        .then(result => {
          if (result.isConfirmed) {
            api
              .delete('/posts/' + this.post.id)
              .then(response => {
                if (response.data.status == 'success') {
                  this.$swal.fire('', '施工事例を削除しました。', 'success')
                  let payload = {
                    status: response.data.status,
                    message: response.data.message
                  }
                  this.$store.commit('setAlert', payload)
                  this.$router.push({ name: 'Post' })
                }
              })
              .catch(e => {
                this.$swal.fire(
                  'サーバーエラー',
                  'おっと！ 何か問題があります。しばらくしてからもう一度お試しください',
                  'error'
                )
              })
          }
        })
    }
  },
  mounted() {
    this.getPost(this.$route.params.id)
  },

  computed: {
    form() {
      return {
        title: this.post.title,
        description: this.post.description,
        content: this.post.content,
        image_alt: this.post.image_alt,
        area: this.post.area,
        status: this.post.status,
        category: this.post.category,
        tag: this.post.tag,
        post_date: this.post.post_date
      }
    },
    formData() {
      var formData = new FormData()
      formData.append('title', this.form.title)
      formData.append('description', this.form.description)
      // Get the Quill editor instance from the ref
      const quillInstance = this.$refs.content.quill
      // Assuming you have a Quill editor instance named quillInstance
      const htmlContent = quillInstance.root.innerHTML
      // Now, htmlContent contains the HTML representation of the content
      formData.append('content', htmlContent)
      formData.append('post_date', this.form.post_date)
      formData.append(
        'status',
        this.post_status ? this.post_status : 'INACTIVE'
      )
      formData.append('tag', this.form.tag)
      formData.append('category', this.form.category)
      formData.append('image_alt', this.form.image_alt)
      formData.append('area', this.form.area ?? '')
      formData.append('images[]', this.post_image)
      return formData
    }
  }
}
</script>
